<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Wallet Account</span>
                <span class="headline" v-else>Create New Wallet Account</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm8 v-if="formType === 'UPDATE'">
                                <v-text-field
                                    label="ID"
                                    hide-details="auto"
                                    data-cy="form-id"
                                    v-model="form.id"
                                    readonly
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-if="formType === 'CREATED'"
                                    label="Keycloak Realm ID*"
                                    data-cy="form-keycloakRealmId"
                                    v-model="form.keycloakRealmId"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-if="formType === 'CREATED'"
                                    label="Username*"
                                    data-cy="form-username"
                                    v-model="form.username"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-if="formType === 'CREATED'"
                                    label="Password*"
                                    data-cy="form-password"
                                    v-model="form.password"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-if="formType === 'CREATED'"
                                    label="Client ID*"
                                    data-cy="form-clientId"
                                    v-model="form.clientId"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    label="Configuration Name*"
                                    data-cy="form-configName"
                                    v-model="form.configName"
                                    :readonly="shouldBeReadOnly"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    label="Account Number*"
                                    data-cy="form-account-number"
                                    v-model="form.accountNumber"
                                    required
                                    :rules="[rules.requiredField]"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete
                                    v-if="formType === 'CREATED'"
                                    v-model="form.tenant"
                                    :items="merchantCategoryCodeList"
                                    item-text="code"
                                    item-value="code"
                                    label="Wallet Tenant*"
                                    required :rules="[rules.requiredField]"
                                    data-cy="form-wallet-tenant-list"
                                    clearable
                                    return-object
                                ></v-autocomplete>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        merchantCodes: Array,
    },
    computed: {
        populatedForm() {
            return {
                keycloakRealmId: this.form.keycloakRealmId,
                tenant: this.form.tenant.code,
                username: this.form.username,
                password: this.form.password,
                clientId: this.form.clientId,
                configName: this.form.configName,
                accountNumber: this.form.accountNumber,
                formType: this.formType
            }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        merchantCodes: {
            handler(value) {
                this.merchantCategoryCodeList = value ? value : [];
            },
        },
        paymentAgents: {
            handler(value) {
                this.paymentAgentList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            shouldBeReadOnly: false,
            merchantCategoryCodeList: this.merchantCodes ?? [],
            form: {
              merchantCategoryCode: {},
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
                properEmail: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid. Ex. juandelacruz@apollo.com.ph'
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-merchant-channel', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    keycloakRealmId: undefined,
                    tenant: undefined,
                    username: undefined,
                    password: undefined,
                    clientId: undefined,
                    configName: undefined,
                    accountNumber: undefined,
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.shouldBeReadOnly = true
                this.form = {
                    id: this.selectedItem.id,
                    keycloakRealmId: this.selectedItem.keycloakRealmId,
                    accountNumber: this.selectedItem.accountNumber,
                    tenant: this.selectedItem.merchantCode ?? {},
                    username: this.selectedItem.username,
                    password: this.selectedItem.password,
                    clientId: this.selectedItem.clientId,
                    configName: this.selectedItem.configName,
                }
            }
        },
    }
}
</script>
