<template>
<div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
    <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" data-cy="form-details"/>
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Wallet Accounts Configuration</h1>
    </v-container>

    <v-card elevation="2" outlined shaped tile padding="20px">
        <div class="d-flex flex-no-wrap justify-space-between">
            <v-card-title>
                <v-container grid-list-md text-xs-center>
                    <v-layout row wrap>
                        <v-flex xs12 sm6>
                            <v-autocomplete
                                class="mt-2"
                                clearable chips
                                :items="merchantCodes" 
                                label="Code" 
                                item-text="code"
                                item-value="code"
                                v-model="filteredTransaction.merchantCode" 
                                return-object
                                data-cy="merchant-code-list"
                                @change="searchMerchantCode">
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-title>
        </div>
        <div class="d-flex flex-no-wrap justify-space-between">
            <v-card-title>
                <v-container grid-list-md text-xs-center>
                    <v-layout wrap>
                        <v-flex xs12 sm6>
                            <v-btn dark medium color=success @click="queryData(true)" class="mt-2" data-cy="action-button-search">
                                <v-icon dark left> mdi-magnify</v-icon>Search
                            </v-btn>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showPaymentAgentMerchForm('CREATED')" data-cy="action-button-create-payment-agent">Create Wallet Account</v-btn>
                            <wallet-accounts-form ref="merchantChannelForm" :selected-item="selectedMerchantChannel" :merchantCodes="listOfwalletTenant" :is-show="showFormDialog" :form-type="merchantChannelFormType" v-on:close-dialog="closeDialog" v-on:save-merchant-channel="saveMerchantChannels" data-cy="form-merchant-channel"/>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-title>
        </div>
    </v-card>

    <template>
        <v-card>
            <v-data-table :hide-default-header="true" :headers="headers" :items="displayMatchedTransactions" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalMatchedTransactions" :footer-props="{'items-per-page-options': [10]}">
                <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr :colspan="headers.length">
                                <th v-for="header in headers" :key="header.text">
                                    <span class="d-flex justify-center">{{header.text}}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.code }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.accountNumber }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.keycloakRealmId }} </span></td>
                                <td> <span class="d-flex justify-center"> {{ item.keycloakClientId }} </span></td>
                                <td>
                                    <span class="d-flex justify-center">
                                        <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewTransaction(item)" data-cy="list-action-button-search">
                                            <v-icon dark medium>mdi-magnify-plus</v-icon>
                                        </v-btn>
                                        <v-btn fab dark x-small color="green" @click.stop="showPaymentAgentMerchForm('UPDATE', item)"  data-cy="list-action-button-update">
                                            <v-icon dark medium>mdi-pencil</v-icon>
                                        </v-btn>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning">
                            Found no results.
                        </v-alert>
                    </template>
            </v-data-table>           
        </v-card>
    </template>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import WalletAccountsForm from '@/views/components/walletAccountsForm.vue'

export default {
    name: 'Unmatched',
    components: {
        Loading,
        DetailsForm,
        WalletAccountsForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
    },
    data() {
        return {
            headers: [
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Code',
                    value: 'code'
                },
                {
                    text: 'Account Number',
                    value: 'accountNumber'
                },
                {
                    text: 'Realm ID',
                    value: 'keycloakRealmId'
                },
                {
                    text: 'Client ID',
                    value: 'keycloakClientId'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                },
            ],
            filteredTransaction: {},
            listOfMerchantCodes: [],
            listOfwalletTenant: [],
            merchantChannelFormType: 'CREATE',
            merchantCodes: this.listOfMerchantCodes ?? [],
            walletTenants: this.listOfwalletTenant ?? [],
            loading: true,
            page: 1,
            limit: '$vuetify.dataFooter.itemsPerPageText',
            totalMatchedTransactions: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            displayMatchedTransactions: [],
            remainderMatchedTransactions: [],
            paymentAgents: [],
            selectedMerchantChannel: {},
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
            totalCount:'',
        }
    },
    watch: {
        options: {
            handler() {
                this.queryData(false);
                this.setData();
            },
        },
        listOfMerchantCodes: {
            handler(value) {
                this.merchantCodes = value
            }
        },
        listOfwalletTenant: {
            handler(value) {
                this.walletTenants = value
            }
        }
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async setData() {
            try {
                const listResponse = await API.getWalletAccounts()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfMerchantCodes = listResponse.accounts
                }
            } catch (e) {
                console.log(e)
            }

            try {
                const listResponse = await API.getWalletTenantList()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfwalletTenant = listResponse.tenants
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var matchedTransactionList = undefined
                
                const parameters = {
                    code: this.filteredTransaction ? this.filteredTransaction.code ?? '' : '',
                    displayName: this.filteredTransaction ? this.filteredTransaction.displayName ?? '' : '',
                    pageNo: pageNumber,
                    pageSize: itemsPerPage
                }

                matchedTransactionList = await API.getWalletAccountList(parameters)
                if (!matchedTransactionList || matchedTransactionList.error) {
                    console.log(`${matchedTransactionList.error}`)
                } else {
                    var filteredList = matchedTransactionList.accounts
                    if (page > 1 && this.remainderMatchedTransactions.length > 0) {
                        filteredList = this.remainderMatchedTransactions.concat(filteredList)
                    }
                    this.totalMatchedTransactions = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayMatchedTransactions = filteredList.slice(0, end)
                    this.remainderMatchedTransactions = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalMatchedTransactions / itemsPerPage

                    parameters.offset = this.totalMatchedTransactions
                    parameters.limit = 1
                    
                    const hasMoreResponse = await API.getWalletAccountList(parameters)
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.accounts
                        if(hasMoreList.length > 0) {
                        this.totalMatchedTransactions = Math.floor(this.totalMatchedTransactions/10) * 10 + 1
                        this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        showPaymentAgentMerchForm(what, item = {}) {
            this.merchantChannelFormType = what
            this.selectedMerchantChannel = item
            this.showFormDialog = true
        },
        searchMerchantCode: async function(val) {
            this.filteredTransaction.merchantCode = val
            // this.queryData(true)
        },
        searchMerchantId(val) {
            this.filteredTransaction.merchantId = val
            // this.queryData(true)
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
                this.$router.go(0);
            }
        },
        async saveMerchantChannels(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new merchant channel`
                    setTimeout(async () => {
                        const createResponse = await API.createWalletAccount(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                            this.showFormDialog = false
                        } else {
                            this.loadingMessage = `Successfully created new merchant channel`
                            this.showFormDialog = false
                            // this.$refs.merchantChannelForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating merchant channel.`
                    setTimeout(async () => {
                        const updateResponse = await API.updateWalletAccount(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                            this.showFormDialog = false
                        } else {
                            this.loadingMessage = `Successfully updated merchant channel.`
                            this.showFormDialog = false
                            // this.$refs.merchantChannelForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.item = transaction
            this.isShowDetails = true
        },
    }
};
</script>
